import React, { useContext, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { up, down } from 'styled-breakpoints'

import AnchorHeadingProvider from '@babelcoder/gatsby-theme-base/src/components/mdx/AnchorHeadingProvider'
import MDXRenderer from '@babelcoder/gatsby-theme-courses/src/components/mdx/Renderer'
import courseTheme from '@babelcoder/gatsby-theme-courses/src/constants/theme'
import TableOfContents from './TableOfContents'
import { Context as UnitsTableContext } from '@babelcoder/gatsby-theme-courses/src/components/mdx/UnitsTableProvider'
import TabList from '@babelcoder/gatsby-theme-base/src/components/TabList'
import Tab from '@babelcoder/gatsby-theme-base/src/components/Tab'
import CommentList from './CommentList'

const Wrapper = styled.div`
  position: relative;
  display: flow-root;

  ${up('large')} {
    margin-top: ${courseTheme.gutter};
    padding: 0 ${courseTheme.gutter};
  }
`

const Details = styled.div`
  ${({ theme }) => css`
    padding: ${courseTheme.gutter};
    background-color: ${theme.colors.neutral.white};

    ${up('large')} {
      width: 65%;
      border-bottom-left-radius: ${theme.round.xlarge};
    }
  `}
`

const Sidebar = styled.aside`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray300};

    ${down('medium')} {
      position: absolute;
      height: 100%;
      right: 0;
      z-index: ${theme.zIndex.ribbon};
    }

    ${up('large')} {
      order: 1;
      width: 35%;
      border-top-right-radius: ${theme.round.xlarge};
      border-bottom-right-radius: ${theme.round.xlarge};
    }
  `}
`

function CourseDetails({ slug, details, tableOfContents, units }) {
  const { setUnits, setCourseType, setCourseSlug } = useContext(
    UnitsTableContext
  )

  useEffect(() => {
    setCourseType('online')
    setCourseSlug(slug)
    setUnits(units)
  }, [slug, units, setCourseSlug, setUnits])

  return (
    <Wrapper>
      <TabList tabIndex="รายละเอียด">
        <Tab title="รายละเอียด">
          <AnchorHeadingProvider>
            <Sidebar>
              <TableOfContents toc={tableOfContents}></TableOfContents>
            </Sidebar>
            <Details>
              <MDXRenderer>{details}</MDXRenderer>
            </Details>
          </AnchorHeadingProvider>
        </Tab>
        <Tab title="ความคิดเห็น">
          <CommentList slug={slug}></CommentList>
        </Tab>
      </TabList>
    </Wrapper>
  )
}

export default CourseDetails
