import React from 'react'
import styled from 'styled-components'
import { FaPlay, FaClock, FaFile, FaGraduationCap } from 'react-icons/fa'
import { up } from 'styled-breakpoints'

import courseTheme from '@babelcoder/gatsby-theme-courses/src/constants/theme'
import CourseMeta from './CourseMeta'

const Wrapper = styled.div`
  margin: ${courseTheme.gutter} 0;

  ${up('large')} {
    padding: 0 ${courseTheme.gutter};
    margin-top: -${courseTheme.gutter};
    margin-bottom: 0;
  }
`

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${up('large')} {
    justify-content: center;
    max-width: ${courseTheme.maxWidth.large};
    margin: 0 auto;
  }
`

function getLessonsByType(units, type) {
  let lessonsByType = []

  for (let { lessons } of units) {
    const filteredLessons = lessons.filter((lesson) => lesson.type === type)

    lessonsByType = [...lessonsByType, ...filteredLessons]
  }

  return lessonsByType
}

function CourseMetas({ meta, units }) {
  // const videos = getLessonsByType(units, 'video')
  // const times = (
  //   videos.reduce((sum, video) => sum + +video.time, 0) / 60
  // ).toFixed(2)
  // console.log(videos.length, times)

  return (
    <Wrapper>
      <Container>
        <CourseMeta
          name="ชั่วโมงเรียน"
          // value={times}
          value={meta.hours}
          icon={FaClock}
        ></CourseMeta>
        <CourseMeta
          name="วิดีโอ"
          // value={videos.length}
          value={meta.videos}
          icon={FaPlay}
        ></CourseMeta>
        <CourseMeta
          name="บทเรียน"
          value={meta.units}
          icon={FaFile}
        ></CourseMeta>
        <CourseMeta
          name="ระดับ"
          value={meta.level}
          icon={FaGraduationCap}
        ></CourseMeta>
      </Container>
    </Wrapper>
  )
}

export default CourseMetas
