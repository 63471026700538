import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO';
import CourseHeader from './CourseHeader';
import CourseDetails from './CourseDetails';
import CourseMetas from './CourseMetas';
import UnitsTableProvider from '@babelcoder/gatsby-theme-courses/src/components/mdx/UnitsTableProvider';
import useCourseSchema from '@babelcoder/gatsby-theme-courses/src/hooks/useCourseSchema';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';

const Wrapper = styled.article`
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.gray200};
  `}
`;

function Course(props) {
  const { slug, title, excerpt, coverImage } = props;
  const {
    src: coverImageURL,
    width: coverImageWidth,
    height: coverImageHeight,
  } = coverImage.childImageSharp.fixed;
  const getSchema = useCourseSchema();
  const { getCoursePath } = useCoursesPath();

  useEffect(() => {
    if (typeof fbq === 'function') {
      // eslint-disable-next-line no-undef
      fbq('track', 'ViewContent', {
        content_type: 'course',
        content_ids: [slug],
        content_name: title,
      });
    }
  }, [slug, title]);

  return (
    <>
      <SEO
        title={title}
        type="article"
        urlPath={getCoursePath(slug)}
        coverImageWidth={coverImageWidth}
        coverImageHeight={coverImageHeight}
        coverImageURL={coverImageURL}
        desc={excerpt}
        schema={getSchema(props)}
        ignoreAuthorAndPublisher={true}
      ></SEO>
      <UnitsTableProvider>
        <Wrapper>
          <CourseHeader {...props}></CourseHeader>
          <CourseMetas {...props}></CourseMetas>
          <CourseDetails {...props}></CourseDetails>
        </Wrapper>
      </UnitsTableProvider>
    </>
  );
}

export default Course;
