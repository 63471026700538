import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { navigate, Link } from 'gatsby';

import {
  useGlobalState,
  useGlobalDispatch,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer';
import ResponsiveVideo from '@babelcoder/gatsby-theme-base/src/components/ResponsiveVideo';
import useRegistration from '@babelcoder/gatsby-theme-courses/src/hooks/useRegistration';
import useCoursesPath from '@babelcoder/gatsby-theme-courses/src/hooks/useCoursesPath';
import useSubscription from '@babelcoder/gatsby-theme-courses/src/hooks/useSubscription';
import courseTheme from '@babelcoder/gatsby-theme-courses/src/constants/theme';
import { SELL_STATUSES } from '@babelcoder/gatsby-theme-courses/src/constants/course';
import numToPrice from '@babelcoder/gatsby-theme-courses/src/utils/numToPrice';

const Header = styled.header`
  ${({ theme, bg }) => css`
    color: ${theme.colors.neutral.gray1500};

    ${up('large')} {
      background-color: ${bg};
      padding: ${courseTheme.gutter};
      padding-bottom: calc(${courseTheme.gutter} * 2);
    }
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-areas:
      'video'
      'title'
      'excerpt'
      'price'
      'register-button';
    position: relative;
    background-color: ${theme.colors.neutral.white};
    overflow: hidden;

    ${up('large')} {
      max-width: ${courseTheme.maxWidth.large};
      grid-template-areas:
        'video title'
        'video excerpt'
        'video price'
        'video register-button';
      grid-template-columns: 65% 1fr;
      grid-template-rows: min-content;
      align-items: center;
      column-gap: ${courseTheme.gutter};
      margin: 0 auto;
      border-radius: ${theme.round.xlarge};
    }
  `};
`;

const VideoWrapper = styled.div`
  grid-area: video;
`;

const Title = styled.h1`
  ${({ theme }) => css`
    grid-area: title;
    font-size: ${theme.fontSizes.large};
    margin: ${courseTheme.gutter} 0;
    padding: 0 ${courseTheme.gutter};
    line-height: 1.5;

    ${up('large')} {
      font-size: ${theme.fontSizes.medium};
      padding-left: 0;
      margin-bottom: 0;
      line-height: 1.25;
    }

    ${up('xlarge')} {
      font-size: ${theme.fontSizes.large};
    }
  `}
`;

const Excerpt = styled.p`
  ${({ theme }) => css`
    grid-area: excerpt;
    margin: 0;
    padding: 0 ${courseTheme.gutter};
    font-size: ${theme.fontSizes.normal};
    color: ${theme.colors.neutral.gray900};

    ${up('large')} {
      padding-left: 0;
    }
  `}
`;

const PriceArea = styled.div`
  grid-area: price;
  display: flex;
  align-items: center;
`;

const Price = styled.div`
  ${({ theme, hasPromotion }) => css`
    font-size: ${theme.fontSizes.large};
    font-family: ${theme.fontFamily.heading};
    padding: 0 ${courseTheme.gutter};

    ${hasPromotion &&
    css`
      color: ${theme.colors.main.danger};
      text-decoration: line-through;
      font-size: ${theme.fontSizes.normal};
    `}

    ${up('large')} {
      font-size: ${theme.fontSizes.medium};
      padding-left: 0;
    }
  `}
`;

const Promotion = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};

    ${up('large')} {
      font-size: ${theme.fontSizes.large};
    }
  `}
`;

const PromotionTime = styled.div`
  ${({ theme }) => css`
    position: absolute;
    font-size: ${theme.fontSizes.small};
    font-family: ${theme.fontFamily.heading};
    width: 200px;
    background: ${theme.colors.main.danger};
    top: 35px;
    right: -50px;
    text-align: center;
    letter-spacing: 1px;
    color: ${theme.colors.neutral.white};
    transform: rotate(45deg);
    z-index: ${theme.zIndex.ribbon};
  `}
`;

function getSellStatusColor(sellStatus, theme) {
  switch (sellStatus) {
    case SELL_STATUSES.INACTIVE:
      return theme.colors.main.danger;
    case SELL_STATUSES.PENDING:
      return theme.colors.main.info;
    case SELL_STATUSES.ACTIVE:
    default:
      return theme.colors.main.primary;
  }
}

const statusButtonStyle = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.medium};
    padding: ${theme.spacers.small} ${theme.spacers.normal};
    margin: ${courseTheme.gutter};
    color: ${theme.colors.neutral.white};
    text-align: center;
    border-radius: 2rem;
    border: none;
    outline: none;

    ${up('large')} {
      margin: 0 ${courseTheme.gutter} ${courseTheme.gutter} 0;
    }
  `}
`;

const StatusButton = styled.button.attrs(({ sellStatus }) => ({
  type: 'button',
  disabled: sellStatus !== SELL_STATUSES.ACTIVE,
}))`
  ${statusButtonStyle};

  ${({ theme, sellStatus }) => css`
    grid-area: register-button;
    background-color: ${getSellStatusColor(sellStatus, theme)};
    ${sellStatus === SELL_STATUSES.ACTIVE && `cursor: pointer`};
  `}
`;

const RegistrationPending = styled.div`
  ${statusButtonStyle};

  background-color: ${({ theme }) => theme.colors.main.info};
`;

const OverViewLink = styled(Link)`
  ${statusButtonStyle};

  background-color: ${({ theme }) => theme.colors.main.primary};
`;

const Note = styled.article`
  ${({ theme }) => css`
    background-color: ${theme.colors.accent.primaryDarken15};
    padding: ${theme.spacers.small};
    color: ${theme.colors.neutral.white};
    font-size: ${theme.fontSizes.normal};
    letter-spacing: 1px;
    text-align: center;
  `}
`;

function getStatusMessage(sellStatus) {
  switch (sellStatus) {
    case SELL_STATUSES.INACTIVE:
      return 'งดจำหน่าย';
    case SELL_STATUSES.PENDING:
      return 'อยู่ระหว่างจัดทำ';
    case SELL_STATUSES.ACTIVE:
    default:
      return 'ลงทะเบียน';
  }
}

function CourseHeader({
  slug,
  title,
  excerpt,
  price,
  promotion,
  promotionTime,
  promotedVideo,
  sellStatus,
  note,
  style: {
    header: { bgColor },
  },
}) {
  const isSSR = typeof window === 'undefined';
  const hasPromotion = !!promotion;
  const { user, isAuthLoaded } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const { subscription, isLoading: isSubscriptionLoading } = useSubscription(
    slug
  );
  const {
    isLoading: isRegistrationLoading,
    exists: registrationExists,
  } = useRegistration(slug);
  const {
    getCoursePath,
    getCourseRegisterPath,
    getCourseOverviewPath,
  } = useCoursesPath();

  const register = useCallback(() => {
    if (sellStatus !== SELL_STATUSES.ACTIVE) return;

    if (!user) {
      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'warning',
          title: 'ต้องการการยืนยันตัวตน',
          message: 'โปรดเข้าสู่ระบบก่อนดำเนินการลงทะเบียน',
        },
      });
      navigate('/auth/sign-in', { state: { prevPath: getCoursePath(slug) } });
      return;
    }

    if (typeof fbq === 'function') {
      // eslint-disable-next-line no-undef
      fbq('track', 'InitiateCheckout', {
        content_ids: [slug],
        content_name: title,
        value: price,
        currency: 'THB',
      });
    }
    navigate(getCourseRegisterPath(slug));
  }, [user, dispatch, slug, sellStatus, getCourseRegisterPath, getCoursePath]);

  const getStatus = useMemo(() => {
    if (!isAuthLoaded) return null;
    if (isSubscriptionLoading) return null;

    if (subscription) {
      return (
        <OverViewLink to={getCourseOverviewPath(slug)}>เริ่มเรียน</OverViewLink>
      );
    }
    if (isRegistrationLoading) return null;

    if (registrationExists) {
      return (
        <RegistrationPending>กำลังตรวจสอบการลงทะเบียน</RegistrationPending>
      );
    }

    return (
      <StatusButton sellStatus={sellStatus} onClick={register}>
        {getStatusMessage(sellStatus)}
      </StatusButton>
    );
  }, [
    slug,
    isAuthLoaded,
    isRegistrationLoading,
    registrationExists,
    sellStatus,
    register,
    subscription,
    isSubscriptionLoading,
    getCourseOverviewPath,
  ]);

  return (
    <>
      {note && <Note>{note}</Note>}
      <Header bg={bgColor}>
        <Container>
          {promotionTime && <PromotionTime>โปร {promotionTime}</PromotionTime>}
          {!isSSR && (
            <React.Suspense fallback={<div />}>
              <VideoWrapper>
                <ResponsiveVideo
                  url={promotedVideo}
                  title={title}
                  desc={excerpt}
                  controls={true}
                ></ResponsiveVideo>
              </VideoWrapper>
            </React.Suspense>
          )}
          <Title>{title}</Title>
          <Excerpt>{excerpt}</Excerpt>
          <PriceArea>
            <Price hasPromotion={hasPromotion}>
              {numToPrice(price)} {!hasPromotion && ' บาท'}
            </Price>
            {hasPromotion && <Promotion>{numToPrice(promotion)} บาท</Promotion>}
          </PriceArea>
          {getStatus}
        </Container>
      </Header>
    </>
  );
}

export default CourseHeader;
