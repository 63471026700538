import React from 'react'
import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

import courseTheme from '@babelcoder/gatsby-theme-courses/src/constants/theme'

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background: ${theme.colors.neutral.white};
    padding: ${courseTheme.gutter};
    width: 50%;
    line-height: 1;

    ${up('large')} {
      flex: 1;
      justify-content: center;

      & + * {
        margin-left: ${courseTheme.gutter};
      }
    }
  `}
`

const MetaValue = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily.heading};
    font-size: ${theme.fontSizes.medium};
    color: ${theme.colors.neutral.gray1200};
    margin-right: calc(${courseTheme.gutter} / 2);
  `}
`

const MetaKey = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray900};
  `}
`

const MetaIconWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.neutral.gray900};
    margin-right: ${theme.spacers.small};
    padding-right: ${theme.spacers.small};
    border-right: 1px dashed ${theme.colors.neutral.gray900};
  `}
`

function MetaIcon({ icon: Icon }) {
  return (
    <MetaIconWrapper>
      <Icon></Icon>
    </MetaIconWrapper>
  )
}

function CourseMeta({ name, value, icon, className }) {
  return (
    <Wrapper className={className}>
      <MetaIcon icon={icon}></MetaIcon>
      <MetaValue>{value}</MetaValue>
      <MetaKey>{name}</MetaKey>
    </Wrapper>
  )
}

export default CourseMeta
