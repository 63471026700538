import React from 'react';
import { graphql } from 'gatsby';

import Course from '../../components/courses/online/Course';

export default function CourseTemplate({ data: { course } }) {
  return <Course {...course}></Course>;
}

CourseTemplate.layout = {
  headerType: 'disabledShadowOnTopHeader',
};

export const pageQuery = graphql`
  query OnlineCourseQuery($slug: String!) {
    course: coursesYaml(slug: { eq: $slug }) {
      slug
      title
      details
      excerpt
      note
      price
      promotion
      promotionTime
      sellStatus
      promotedVideo
      tableOfContents
      coverImage: image {
        childImageSharp {
          fixed(width: 730, height: 415, quality: 50, webpQuality: 50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      units {
        desc
        title
        lessons {
          title
          desc
          type
          slug
          time
          preview
        }
      }
      style {
        header {
          bgColor
        }
      }
      meta {
        videos
        units
        hours
        level
      }
    }
  }
`;
